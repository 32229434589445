<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('notification.notifications') }}</h2>
    </div>

    <el-card class="box-card" style="margin-right: 100px;margin-left: 100px;">
      <span v-for="notification in notifications" :key="notification.id">
        <el-row v-if="notification.lu" :gutter="20">
          <el-col :span="22">
            <el-alert
              :title="notification.titre"
              type="info"
              :description="notification.contenu"
              :close="closeNotif(notification.element_id)"
              :closable="false"
              show-icon
              style="margin-bottom:10px;"
            />
          </el-col>
          <el-col :span="1">
            <el-button type="danger" icon="el-icon-delete" circle @click="handleDeleteNotification(notification.id,true)" />
          </el-col>
        </el-row>
        <el-row v-if="!notification.lu" :gutter="20">
          <el-col :span="24">
            <el-alert
              v-if="!notification.lu"
              type="warning"
              :title="notification.titre"
              :description="notification.contenu"
              :closable="false"
              show-icon
              style="margin-bottom:10px;"
            />
          </el-col>
        </el-row>
      </span>

      <div v-loading="loading" />
      <el-empty v-if="notifications === null && !loading" description="Aucune donnée" />
    </el-card>

    <el-dialog
      :title="$t('notification.notificationDetails')"
      :visible.sync="dialogInfoNotification"
      :before-close="handleCloseCampagneagricoleCreateDraw"
      width="30%"
    >
      <div class="content-inputs">
        <span>ok ok</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align:right;">
          <el-button plain type="danger" @click="dialogInfoNotification=false">
            {{ $t('permission.cancel') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';

const notificationsResource = new Resource('notifications');
const auditItemsRessource = new Resource('audits');

export default {
  name: 'LotsList',
  // components: { Pagination },
  components: {},
  directives: { waves, permission, role },
  data() {
    return {
      notifications: [],

      tableData: [],
      tableHeader: [],
      drawer: false,
      list: null,
      total: 0,
      detail: false,
      detailData: [],
      detailM: false,
      detailDataM: null,
      loading: true,
      downloading: false,
      lotCreating: false,
      editing: false,
      zones: null,
      communes: null,
      communesBack: null,
      villagesBack: null,
      villages: null,
      zoneFilter: null,
      nomZones: [],
      nomCommunes: [],
      nomVillages: [],
      communeFilter: null,
      villageFilter: null,
      activeList: 'disponibles',
      producteurFilter: null,
      currentLot: {},
      activeName: '1',
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      dialogInfoNotification: false,
      currentLotId: 0,
      rules: {
        ste_trans: [{ required: true, message: this.$t('lot.ste_transRequired'), trigger: 'blur' }],
        im_camion: [{ required: true, message: this.$t('lot.im_camionRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  created() {
    this.getLotsList();
  },
  mounted() {
    this.getNotificationsList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array) {
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    async getNotificationsList(){
      const { data } = await notificationsResource.list();
      console.log('notifications', data);
      this.notifications = data;
    },
    async openNotif(id){
      console.log('openNoti', id);
      window.location.href = '/audit/' + id;
      // var data = await auditItemsRessource.get(this.$route.params.id);
    },
    async closeNotif(id){
      console.log('supprimer Notification');
      return 0;
    },
    handleDeleteNotification(id, visible) {
      this.$confirm(this.$t('notification.deleteWarn') + ' ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        notificationsResource.destroy(id).then(response => {
          this.$message({
            type: 'success',
            message: this.$t('notification.suppressionEffectue'),
          });
          this.initSearch();
        }).catch(error => {
          console.log(error);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    async getAudit(id) {
      // const { data } = await producteursResource.get(this.$route.params.id);
      var data = await auditItemsRessource.get(this.$route.params.id);
      if (data.success){
        console.log('Audit : ', data);
        this.audit = data.data;
        this.date = moment().format('DD-MM-YYYY');
        this.audit.date_audit = this.audit.date_audit ? moment(this.audit.date_audit).format('DD-MM-YYYY') : '';
        this.loading = false;
      } else {
        this.loading = true;
        this.$message({
          type: 'error',
          message: data.message,
        });
      }
    },
    resetNewLot() {
      this.currentLot = {
        code: '',
        ste_trans: '',
        im_camion: '',
        date_chrgmnt: '',
      };
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    navigateAudit(id) {
      this.$router.push({ path: '/audits/' + id });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}

.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}

.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}

.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  .block {
    float: left;
    min-width: 250px;
  }

  .clear-left {
    clear: left;
  }
}
</style>
