<template>
  <div class="app-container">
    <div class="page-title">
      <h2>Formation</h2>
    </div>

    <!-- <el-card class="box-card"> -->
    <div class="filter-container">
      <el-button v-if="!data.resume" style="margin-left: auto" class="mr-15 filter-item" type="info" icon="el-icon-circle-check" @click="handleTerminerFormation()">
        Terminer
      </el-button>
      <el-button style="margin-left: auto" class="mr-15 filter-item" type="primary" icon="el-icon-edit" @click="handeleEditFormation()">
        Modifier
      </el-button>
      <el-button v-if="data.resume" type="info" icon="el-icon-download" @click="generateFormationPdf()">Télécharger en PDF</el-button>
    </div>
    <el-row>
      <!--      <el-col :span="7" style="margin-right:7px">-->
      <!--        <div class="grid-content bg-purple">-->
      <!--          <el-card class="box-card">-->
      <!--            <div slot="header" class="clearfix">-->
      <!--              <span style="font-weight:bold">Informations Formateur</span>-->
      <!--              &lt;!&ndash; <el-button style="float: right; padding: 3px 0" type="text">Bouton</el-button> &ndash;&gt;-->
      <!--            </div>-->
      <!--            <div class="text item" style="display: flex; align-items: center; flex-direction:column">-->
      <!--              <div class="pan-thumb" />-->
      <!--              <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ data.nom + ' ' + data.prenom }}</span>-->
      <!--              <span style="font-style: italic;">{{ data.sexe ==='f'?'Féminin':'Masculin' | uppercaseFirst }}</span>-->
      <!--            </div>-->
      <!--          </el-card>-->
      <!--        </div>-->
      <!--      </el-col>-->
      <el-col :span="16">
        <div class="grid-content bg-purple-light">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold;">Informations sur la Formation</span>
            </div>
            <el-tabs tab-position="top">
              <el-tab-pane label="Informations">
                <el-table v-loading="loading" :data="formationInfo" :show-header="false">
                  <el-table-column>
                    <template slot-scope="scope">
                      <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <span style="font-style: italic; white-space: pre-line;" v-html="scope.row.valeur" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Participants">
                <el-table :data="participants_formations" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Nom prenoms" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nom }} {{ scope.row.prenom }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Numéro" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.numero }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="1===1 && $t('general.actions')" width="200">
                    <template slot-scope="scope">
                      <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateProducteur(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="Terminer la Formation"
      :visible.sync="terminerFormationDialogue"
      width="30%"
      center
    >
      <el-form ref="endForm" :rules="rules" :model="formationTerminerData" label-position="left">
        <el-form-item label="producteurs" prop="up_id">
          <el-select
            v-model="prod_name"
            multiple
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a keyword"
            :remote-method="filterProducteurs"
            :loading="loading"
            @change="getProducteurSelection"
          >
            <div v-if="!data.ci">
              <el-option
                v-for="item in producteurs"
                :key="item.id"
                :label="item.nom_prenom"
                :value="item.id"
              />
            </div>
            <div v-else>
              <el-option
                v-for="item in controleurs"
                :key="item.id"
                :label="item.nom + ' ' + item.prenom"
                :value="item.id"
              />
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="Resumé de la formation" prop="resume">
          <el-input v-model="data.resume" type="textarea" />
        </el-form-item>
        <el-form-item label="À retenir" prop="a_retenir">
          <el-input v-model="data.a_retenir" type="textarea" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="terminerFormationDialogue = false">Annuler</el-button>
        <el-button :disabled="false" type="primary" @click="terminerFormation">Confirmer</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Modification de la formation"
      :visible.sync="dialogEditFormation"
      width="30%"
    >
      <div class="content-inputs">
        <el-form ref="formationForm" :rules="rules" :model="data" label-position="left">
          <div class="ligne">
            <el-form-item :label="$t('controleur.controleur')" prop="controleur_id">
              <el-select v-model="data.controleur_id" class="filter-item" :placeholder="$t('controleur.controleur')" style="width: 100%;" prop="controleur_id">
                <el-option
                  v-for="(controleur, idex) in controleurs"
                  :key="idex"
                  :label="controleur.nom + '    '+ controleur.prenom | uppercaseFirst"
                  :value="controleur.id"
                  :disabled="!controleur.is_active"
                />
              </el-select>
            </el-form-item>
            <!--            <el-button class="bouton" type="primary" :icon="dialogCreateControleur ? 'el-icon-minus' : 'el-icon-plus'" circle @click="dialogCreateControleur=!dialogCreateControleur" />-->
          </div>

          <el-form-item label="Participants:" prop="participants"><br>
            <el-tag
              v-for="(participant, index) in participants_formations"
              :key="index"
              closable
              @close="removeParticipant(participant.id)"
            >
              {{ participant.nom_prenom ? participant.nom_prenom : participant.nom + ' ' + participant.prenom }}</el-tag>
            <div v-if="!data.ci">
              <el-select
                v-model="prod_name"
                multiple
                filterable
                remote
                reserve-keyword
                placeholder="Entrez le nom d'un producteur"
                :remote-method="filterProducteurs"
                :loading="loading"
                @change="getProducteurSelection"
              >
                <el-option
                  v-for="item in producteurs"
                  :key="item.id"
                  :label="item.nom + ' ' + item.prenom"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div v-else>
              <el-select
                v-model="prod_name"
                multiple
                filterable
                remote
                reserve-keyword
                placeholder="Please enter a keyword"
                :remote-method="filterControleurs"
                :loading="loading"
                @change="getProducteurSelection"
              >
                <el-option
                  v-for="item in controleurs"
                  :key="item.id"
                  :label="item.nom + ' ' + item.prenom"
                  :value="item.id"
                />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item :label="$t('formation.theme')" prop="theme">
            <el-input v-model="data.theme" />
          </el-form-item>
          <el-form-item :label="$t('formation.objectifs')" prop="objectifs">
            <el-input v-model="data.objectifs" type="textarea" rows="5" />
          </el-form-item>
          <el-form-item :label="$t('formation.lieu')" prop="lieu">
            <el-input v-model="data.lieu" />
          </el-form-item>
          <el-form-item :label="$t('formation.date_for')" prop="date_for">
            <el-date-picker
              v-model="data.date_for"
              type="date"
              placeholder="Choississez un jour"
              style="width: 100%;"
            />
          </el-form-item>
          <el-form-item :label="$t('formation.duree_for')" prop="duree_for">
            <el-input-number
              v-model.number="data.duree_for"
              type="number"
              :min="1"
              :max="60"
              maxlength="3"
              :placeholder="$t('formation.duree_for')"
              style="width: 100%;"
            />
          </el-form-item>
        </el-form>
        <p style="font-weight:bold;"><span style="color:red">*</span> Type de formation (Cibles de la Formation) : </p>
        <el-radio-group v-model="data.ci" size="medium" prop="type_formation">
          <el-radio-button :label="true">Controleurs</el-radio-button>
          <el-radio-button :label="false">Producteurs</el-radio-button>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align:right;">
          <el-button plain type="danger" @click="dialogEditFormation=false">
            {{ $t('permission.cancel') }}
          </el-button>
          <el-button type="primary" :loading="formationCreating" @click="updateFormation()">
            {{ formationCreating ? $t('general.saving') : $t('general.save') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const producteurRessource = new Resource('producteurs');
const producteurAllRessource = new Resource('producteurs-all');
const controleursRessource = new Resource('controleurs');
const formationResource = new Resource('formations');
const formationRessourcePdf = new Resource('fiche/formation');
const affectRessource = new Resource('formation_affecter_participants');
export default {
  name: 'FormationInfo',
  // components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      data: {},
      date: '',
      prod_name: '',
      dialogEditFormation: false,
      formationCreating: false,
      formation_id: null,
      terminerFormationDialogue: false,
      producteurs: {},
      formationTerminerData: {},
      participants_formations: [],
      controleurs: [],
      formateur: {},
      fullscreenLoading: true,
      formationInfo: [],
      parcelles: null,
      printing: false,
      list: null,
      total: 0,
      sexe: '',
      image: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      nomVillages: null,
      nomGroupements: null,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      loading: true,
      participantsAffecte: [],
      downloading: false,
      villages: null,
      groupements: null,
      activeList: 'disponibles',
      producteurCreating: false,
      editing: false,
      persinfos: null,
      organInfos: null,
      currentProducteur: {},
      dialogCreateProducteur: false,
      currentProducteurId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('producteur.NomRequired'), trigger: 'blur' }],
        prenom: [{ required: true, message: this.$t('producteur.PrenomRequired'), trigger: 'blur' }],
        numero: [{ required: true, message: this.$t('producteur.NumeroRequired'), trigger: 'blur' }],
        adresse: [{ required: true, message: this.$t('producteur.AdresseRequired'), trigger: 'blur' }],
        controleur_id: [{ required: true, message: 'Ce champ est obligatoire', trigger: 'blur' }],
        type_formation: [{ required: true, message: 'Ce champ est obligatoire', trigger: 'blur' }],
      },
      fileList: [],
      mininfos: [],
    };
  },
  computed: {

  },
  created() {
    this.getFormation();
    this.getControleur();
  },
  methods: {
    checkPermission,
    checkRole,
    async generateFormationPdf(){
      var { data } = await formationRessourcePdf.getPdf(this.$route.params.id, this.$store.getters.camp_id);
      window.open(data, '_blank');
      console.log('PDF : ', data);
    },
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleTab(tab){
      if (tab._props.label === 'Participants'){
        if (this.participants_formations.length === 0){
          this.getParticipants();
        }
      }
    },
    async handeleEditFormation(){
      this.dialogEditFormation = true;
      this.participantsAffecte = [];
      await this.listProducteurs();
      this.participants_formations.forEach((item) => {
        this.participantsAffecte.push(item.id === undefined ? item : item.id);
      });
      console.log('PARTICIPANTS AFFECTES', this.participantsAffecte);
    },

    updateFormation() {
      this.$refs['formationForm'].validate((valid) => {
        if (valid) {
          this.formationCreating = true;
          this.participantsAffecte = this.participantsAffecte.filter(function(item, index, inputArray) {
            return inputArray.indexOf(item) === index;
          });
          const update_participants = {};
          update_participants.resume = this.data.resume;
          update_participants.participants = this.participantsAffecte;
          this.data.participants = this.participantsAffecte;
          console.log('FORMATION EDITING FINALLY', this.data);
          affectRessource.extra(this.$route.params.id, update_participants);
          formationResource.update(this.$route.params.id, this.data).then(response => {
            this.getFormation();
            this.$message({
              message: this.$t('formation.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogEditFormation = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.formationCreating = false;
            });
        } else {
          console.log('Erreur !!', this.data.ci);
          return false;
        }
      });
    },
    async handleTerminerFormation(){
      console.log('EN COURS');
      this.terminerFormationDialogue = true;
      console.log('Les producteurs', this.producteurs);
    },
    terminerFormation() {
      this.$refs['endForm'].validate((valid) => {
        if (valid && this.formationTerminerData.resume !== '' && this.data.a_retenir && this.data.resume && this.formationTerminerData.participants) {
          this.formationTerminerData.resume = this.data.resume;
          this.formationTerminerData.a_retenir = this.data.a_retenir;
          console.log('ID DE LA FORMATION', this.formation_id);
          console.log('DATA TERMINER FORMATION', this.formationTerminerData);
          affectRessource.extra(this.$route.params.id, this.formationTerminerData).then((response) => {
            this.getFormation();
            this.$message({
              message: this.$t('formation.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.terminerFormationDialogue = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.formationCreating = false;
            });
        } else {
          this.$message({
            message: 'veuillez bien renseigner tout les champs',
            type: 'error',
            duration: 5 * 1000,
          });
          console.log('Erreur !!');
          return false;
        }
      });
    },
    getProducteurSelection(recuperer){
      this.participantsAffecte = this.participantsAffecte.concat(recuperer);
      console.log('RECUPERER ', recuperer);
      this.formationTerminerData.participants = this.participantsAffecte;
    },
    removeParticipant(id){
      this.participants_formations = this.participants_formations.filter((participants) => {
        return participants.id !== id;
      });
      this.participantsAffecte.splice(this.participantsAffecte.indexOf(id), 1);
      console.log('REMOVE PARTICIPANTS', this.participantsAffecte);
    },
    async filterProducteurs(name){
      const query2 = {
        keyword: name,
      };
      const { data } = await producteurAllRessource.list(query2);
      let info_participants = [];
      info_participants = data;
      console.log('LIST DES PRODUCTEUR ', info_participants);
      this.producteurs = info_participants;
      console.log('V MODEL ', this.prod_name);
    },
    async filterControleurs(name){
      const query2 = {
        page: 1,
        limit: 10,
        keyword: name,
      };
      const { data } = await controleursRessource.list(query2);
      const info_participants = [];
      data.forEach((controleur) => {
        info_participants.push({ id: controleur.id, nom_prenom: controleur.nom + ' ' + controleur.prenom });
      });
      // if (this.participants_formations.length !== 0){
      //   // for (const exist_participant of this.participants_formations){
      //   //   info_participants.filter((found_info) =>{
      //   //     return found_info.id !== exist_participant.id;
      //   //   })
      //   // }
      //   info_participants = info_participants.filter((found_info) => !this.participants_formations.includes(found_info));
      //   console.log('LIST DES CONTROLEUR filtre', info_participants);
      // }
      this.controleurs = info_participants;
      console.log('V MODEL ', this.prod_name);
      console.log('LIST DES PRODUCTEUR RETROUVE', data);
    },
    navigateProducteur(id){
      if (this.data.ci){
        console.log('azerty: ', id);
        this.$router.push({ path: '/controleur/' + id });
      } else {
        this.$router.push({ path: '/producteurs/' + id });
      }
    },
    async listProducteurs(){
      const list_producteurs = [];
      for (const participant of this.data.participants) {
        const { data } = await producteurRessource.get(participant.id);
        list_producteurs.push(data);
      }
      this.participants_formations = list_producteurs;
    },
    async getParticipants(){
      if (!this.data.ci){
        await this.listProducteurs();
        console.log('PARTICIPANTS', this.participants_formations);
      } else {
        this.participants_formations = this.controleurs;
        console.log('LES PARTICIPANTS', this.participants_formations);
      }
    },
    async getControleur(){
      const { data } = await controleursRessource.list(this.query);
      this.controleurs = data;
    },
    async getFormation() {
      // const { data } = await producteursResource.get(this.$route.params.id);
      var { data } = await formationResource.get(this.$route.params.id);
      this.data = data;
      this.formation_id = this.$route.params.id;
      console.log('LA FORMATION : ', this.data);
      this.fullscreenLoading = false;
      console.log('le loading : ', this.fullscreenLoading);
      this.date = moment().format('DD-MM-YYYY');
      this.participants_formations = this.data.participants;
      this.formationInfo = [
        {
          'libelle': 'Formateur    :',
          'valeur': data.formateur !== null ? data.formateur : 'INDISPONIBLE',
        },
        {
          'libelle': 'thème    :',
          'valeur': data.theme !== null ? data.theme : 'INDISPONIBLE',
        },
        {
          'libelle': 'Objectif    :',
          'valeur': data.objectifs !== null ? data.objectifs : 'INDISPONIBLE',
        },
        {
          'libelle': 'lieu    :',
          'valeur': data.lieu !== null ? data.lieu : 'INDISPONIBLE',
        },
        {
          'libelle': 'Resume',
          'valeur': data.resume !== null ? data.resume : 'INDISPONIBLE',
        },
        {
          'libelle': 'À retenir',
          'valeur': data.a_retenir !== null ? data.a_retenir : 'INDISPONIBLE',
        },
        {
          'libelle': 'Durée',
          'valeur': data.duree_for !== null ? data.duree_for + ' H' : 'INDISPONIBLE',
        },
        {
          'libelle': 'Date  Formation',
          'valeur': data.date_for !== null ? data.date_for : 'INDISPONIBLE',
        },
      ];
      this.loading = false;
      this.loading = false;
      // console.log('tableau : ', this.mininfos);
      // if (data !== null) {
      //   window.print();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.pan-thumb {
  width: 150px;
  height: 150px;
  background-position: center center;
  background-size: cover;
  border-radius: 75px;
  overflow: hidden;
  margin-bottom: 20px;
  // position: absolute;
  background-image:url('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg');
  // transform-origin: 95px 40px;
  // transition: all 0.3s ease-in-out;
  background-repeat:no-repeat;
}
</style>
